<template>
<div>
    <div class=" bg-dark p-3 mt-3 full-width mb-5" >
                <h4 class="text-white mt-3">Client Management</h4>
    </div>
    <b-container class="bg-color p-4">
    <b-row>
        <b-col>
            <h5><span class="font-weight-bolder"> Client ID:</span>00000000</h5>
        </b-col>
       <b-col>
           <h5 class="float-right"><span class="font-weight-bolder "> Time Zone:</span> US/Eastern</h5>
       </b-col>



    </b-row>
        <hr >
        <b-row>
            <b-col >
                <h6 class="font-weight-bolder"> Client Information</h6>
                <div class="adjust-line-height pt-3">
                <p><span class="font-weight-bolder "> Name:</span></p>
                <p><span class="font-weight-bolder "> Email:</span></p>
                <p><span class="font-weight-bolder "> Phone:</span></p>
                <p><span class="font-weight-bolder "> Address:</span></p>
                </div>
            </b-col>
            <b-col class="pt-5 mt-5">
                <b-btn class="float-right " size="sm" variant="dark" style="border-radius: 0px !important;">Edit</b-btn>
            </b-col>
        </b-row>
        <hr >
        <b-row>
            <b-col >
                <h6 class="font-weight-bolder"> Company</h6>
                <div class="adjust-line-height pt-3">
                    <p><span class="font-weight-bolder "> Name:</span></p>
                    <p><span class="font-weight-bolder "> Email:</span></p>
                    <p><span class="font-weight-bolder "> Phone:</span></p>
                    <p><span class="font-weight-bolder "> Address:</span></p>
                </div>
            </b-col>
            <b-col class="pt-5 mt-5">
                <b-btn class="float-right " size="sm" variant="dark" style="border-radius: 0px !important;">Edit</b-btn>
            </b-col>
        </b-row>

    </b-container>
    <b-container class="mt-3">
        <b-row>
            <b-col>
                <h6><span class="font-weight-bolder"> Date Created:</span></h6>
            </b-col>
            <b-col>
                <h6 class="float-right"><span class="font-weight-bolder "> Status:</span> US/Eastern</h6>
            </b-col>



        </b-row>
    </b-container>

    <div class=" bg-dark p-4 mt-3 full-width" >
        <div class="container" >
            <div class="row">
                <div class="col-md-6 col-sm-6 col-xs-12  ">
                    <b-button  variant="primary" class="font-weight-bold "
                               style="border-radius: 0px !important;">Add A Product</b-button>

                </div>

                <div class="col-md-6 col-sm-6 col-xs-12 text-center  " >
                    <div>
                        <b-button  variant="primary" class="font-weight-bold float-right"
                                   style="border-radius: 0px !important;">Manage Products</b-button>
                    </div>

                </div>
            </div>
        </div>
    </div>

</div>
</template>

<script>
export default {
    name: "clientManagement",
    data(){
        return{

        }
    },
    created(){
        this.$root.preloader = false;
    }
}
</script>

<style scoped>
.bg-color{
    background-color: #EBEBEB;;
}
.adjust-line-height {
    line-height: .8em;
}
.full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
</style>
